import './App.css';
import { Stream } from "@cloudflare/stream-react"

function App() {
  const videoIdOrSignedUrl = "ee16b547a2387a1114f3e1a5cc5d4257";
  return (
    <div className="App">
      <Stream src={videoIdOrSignedUrl} controls="true" autoplay="true" />
    </div>
  );
}

export default App;
